"use client";

import { useCallback, useSyncExternalStore } from "react";

interface WindowBreakpoints {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  isXl2: boolean;
}

/**
 * adding this hook beacuse CSS media queries can only hide elements, not remove them from the DOM
 * we need control over the number of flex children which keeps changing based on screen size
 */

export function useWindowSize(): WindowBreakpoints {
  const getSnapshot = () => {
    if (typeof window === "undefined") {
      return 0;
    }
    return window.innerWidth;
  };

  const subscribe = useCallback((callback: () => void) => {
    window.addEventListener("resize", callback);
    return () => window.removeEventListener("resize", callback);
  }, []);

  const width = useSyncExternalStore(subscribe, getSnapshot, () => 0);

  return {
    isXs: width >= 360 && width < 640,
    isSm: width >= 640 && width < 768,
    isMd: width >= 768 && width < 1024,
    isLg: width >= 1024 && width < 1280,
    isXl: width >= 1280 && width < 1536,
    isXl2: width >= 1536,
  };
}
