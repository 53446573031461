import { css } from "@emotion/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import TestimonialHero from "./TestimonialHero";
import { mq } from "@/constants/breakpoints";

export interface Testimonial {
  quote: string;
  quoteAuthor: string;
  organizationName: string;
  organizationLogo: any;
}

interface HomepageHeroTestimonialsProps {
  testimonials: Testimonial[];
}

const HomepageHeroTestimonials = ({
  testimonials,
}: HomepageHeroTestimonialsProps) => {
  return (
    <Swiper
      modules={[FreeMode, Autoplay]}
      spaceBetween={40}
      freeMode={{
        enabled: true,
        sticky: true,
      }}
      centeredSlides={false}
      autoplay={{
        delay: 3000,
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
        stopOnLastSlide: false,
      }}
      // slidesPerView="auto"
      loop
      breakpoints={{
        360: {
          slidesPerView: 1,
          spaceBetween: 8,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        1536: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
      }}
      css={css`
        margin-top: 14px;
        margin-left: 0;
        margin-right: 0;
        padding-right: 16px;
        mask-image: linear-gradient(to left, transparent 0%, black 20%);
        ${mq["sm"]} {
          margin-left: 6px;
          margin-right: 16px;
        }
        ${mq["md"]} {
          margin-left: 24px;
        }

        ${mq["lg"]} {
          margin-top: 14px;
          margin-left: 0px;
          margin-right: 0px;
        }
        .swiper-slide {
          width: 326px;
          height: 100%;
        }
        // override globals introduced by <Testimonials /> component.
        .swiper-slide-next,
        .swiper-slide-prev {
          mask-image: none !important;
          -webkit-mask-image: none !important;
          -moz-mask-image: none !important;
        }
      `}
    >
      {testimonials.map((testimonial: any, index: number) => (
        <SwiperSlide key={index}>
          <TestimonialHero testimonial={testimonial} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HomepageHeroTestimonials;
