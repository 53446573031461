import { css } from "@emotion/react";
import Image from "next/image";
import { Body } from "@/components/Typography";
import { mq } from "@/constants/breakpoints";

interface ReviewBadgeHeroProps {
  imageSrc: string;
  text: string;
}

export function ReviewBadgeHero({ imageSrc, text }: ReviewBadgeHeroProps) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 8px;
        padding: 4px 12px 4px 4px;
        background: #0000001a;
        border-radius: 32px;
        ${mq["xs"]} {
          flex-grow: unset;
        }
        ${mq["sm"]} {
          width: 100%;
          flex-grow: 1;
        }
        ${mq["sm"]} {
          width: auto;
          flex-grow: unset;
        }
      `}
    >
      <Image src={imageSrc} width={24} height={24} alt="" />
      <Body
        css={css`
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          flex: 1;
        `}
      >
        {text}
      </Body>
    </div>
  );
}
