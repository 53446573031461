import { mq } from "@/constants/breakpoints";
import { demoPath } from "@/constants/paths";
import { css } from "@emotion/react";
import Link from "next/link";
import Balancer from "react-wrap-balancer";
import Button from "../Buttons/Button";
import GetStartedButton from "../Buttons/GetStartedButton";
import Container from "../Container/Container";
import {
  Heading,
  H5Css,
  H2Css,
  Body,
  BodyMedium14Css,
  BodyMedium20Css,
} from "../Typography";

interface BottomCTAProps {}

const BottomCTA = ({}: BottomCTAProps) => {
  return (
    <div
      css={css`
        background-color: #c1efff;
        background-image: url(/images/bottom-cta-bg.svg);
        background-size: cover;
        background-repeat: no-repeat;
        padding: 60px 0;
        color: #fff;
        text-align: center;
      `}
    >
      <Container>
        <Heading
          level={3}
          css={css`
            ${H5Css}
            ${mq["lg"]} {
              ${H2Css}
            }
          `}
        >
          Ready to get started?
        </Heading>
        <div
          css={css`
            max-width: 560px;
            margin: auto;
          `}
        >
          <Body
            css={css`
              ${BodyMedium14Css}
              margin-top: 14px;
              ${mq["lg"]} {
                ${BodyMedium20Css}
                margin-top: 24px;
              }
            `}
          >
            <Balancer>
              Create an account to see how Jersey Watch has helped over{" "}
              {process.env.organizationCount} sports organizations grow.
            </Balancer>
          </Body>
        </div>
        <div
          css={css`
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: center;
            justify-content: center;
            ${mq["sm"]} {
              flex-direction: row;
            }
            ${mq["lg"]} {
              margin-top: 32px;
            }
          `}
        >
          <GetStartedButton />
          <Link href={demoPath}>
            <Button jwType="lightOutline">Watch Demo</Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default BottomCTA;
