import { gray500Color, gray600Color } from "@/constants/colors";
import { css } from "@emotion/react";
import { Body, BodyMedium14Css, BodyMedium16Css } from "../Typography";
import Image from "next/image";
import { mq } from "@/constants/breakpoints";
import { contentfulLoader } from "../ContentfulImage/ContentfulImage";
import { Testimonial } from "./HomepageHeroTestimonials";

interface TestimonialHeroProps {
  testimonial: Testimonial;
}

const TestimonialHero = ({ testimonial }: TestimonialHeroProps) => {
  return (
    <div
      css={css`
        padding: 12px;
        background-color: white;
        border-radius: 16px;
        height: 153px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 10px;
          height: 100%;
        `}
      >
        <Image
          width="71"
          height="71"
          loader={contentfulLoader}
          src={
            testimonial.organizationLogo.url ||
            testimonial.organizationLogo.fields?.file?.url
          }
          alt={testimonial.organizationName}
          css={css`
            flex-shrink: 0;
          `}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: space-between;
          `}
        >
          <Body
            css={css`
              color: ${gray600Color};
              ${BodyMedium14Css}
              ${mq["lg"]} {
                ${BodyMedium16Css}
              }
            `}
          >
            &ldquo;{testimonial.quote}&rdquo;
          </Body>
          <Body
            css={css`
              color: ${gray500Color};
              ${BodyMedium14Css}
              ${mq["lg"]} {
                ${BodyMedium16Css}
              }
            `}
          >
            {testimonial.organizationName}
          </Body>
        </div>
      </div>
    </div>
  );
};

export default TestimonialHero;
