import { css } from "@emotion/react";
import { Body } from "@/components/Typography";
import GetStartedButton from "@/components/Buttons/GetStartedButton";

interface GetStartedButtonHeroProps {
  disclaimer?: string;
}

export function GetStartedButtonHero({
  disclaimer,
}: GetStartedButtonHeroProps) {
  return (
    <div
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
      `}
    >
      <GetStartedButton
        css={css`
          width: 100%;
          min-width: 232px;
          max-width: 350px;
          margin: auto;
          background: #59ffa0;
          color: black;
          :hover {
            background: #59ffa0;
            transition: background-color 0.3s ease;
            filter: hue-rotate(20deg);
          }
        `}
      />
      {disclaimer && (
        <Body
          css={css`
            margin-top: 8px;
            opacity: 70%;
          `}
        >
          {disclaimer}
        </Body>
      )}
    </div>
  );
}
