import { mq } from "@/constants/breakpoints";
import { darkColor } from "@/constants/colors";
import { css } from "@emotion/react";
import ArrowButton from "../Buttons/ArrowButton";
import Container from "../Container/Container";
import { Heading, H5Css, H3Css } from "../Typography";
import Testimonial from "./Testimonial";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Balancer from "react-wrap-balancer";

interface TestimonialsProps {
  testimonials: any[];
}

const maskImageValue = (direction: "left" | "right") =>
  `linear-gradient(to ${direction}, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 30%)`;

const Testimonials = ({ testimonials }: TestimonialsProps) => {
  const showButtons = testimonials.length > 1;
  return (
    <>
      <Container>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 60px;
            gap: 15px;
            ${mq["lg"]} {
              margin-top: 120px;
            }
          `}
        >
          {showButtons && (
            <ArrowButton
              direction="left"
              className="swiper-button-prev"
              css={css`
                display: none;
                min-width: 50px;
                ${mq["lg"]} {
                  display: block;
                }
              `}
            />
          )}
          <Heading
            level={2}
            css={css`
              color: ${darkColor};
              text-align: center;
              width: 100%;
              ${H5Css}
              ${mq["lg"]} {
                ${H3Css}
              }
            `}
          >
            {" "}
            <Balancer>Award-Winning Customer Support</Balancer>
          </Heading>
          {showButtons && (
            <ArrowButton
              className="swiper-button-next"
              direction="right"
              css={css`
                display: none;
                min-width: 50px;
                ${mq["lg"]} {
                  display: block;
                }
              `}
            />
          )}
        </div>
      </Container>
      <Swiper
        spaceBetween={40}
        centeredSlides
        slidesPerView="auto"
        loop
        autoplay={{
          delay: 5000,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Pagination, Navigation, Autoplay]}
        pagination={{ clickable: true }}
        css={css`
          margin-top: 24px;
          margin-left: 16px;
          margin-right: 16px;
          ${mq["sm"]} {
            margin-left: 0;
            margin-right: 0;
          }
          ${mq["lg"]} {
            margin-top: 65px;
          }
          .swiper-slide {
            max-width: 600px;
            ${mq["lg"]} {
              max-width: 858px;
            }
          }
        `}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <Testimonial testimonial={testimonial} />
          </SwiperSlide>
        ))}
      </Swiper>
      <style global jsx>{`
        ${showButtons
          ? `
        .swiper-slide-prev {
          -webkit-mask-image: ${maskImageValue("left")};
          -moz-mask-image: ${maskImageValue("left")};
          mask-image: ${maskImageValue("left")};
        }
        .swiper-slide-next {
          -webkit-mask-image: ${maskImageValue("right")};
          -moz-mask-image: ${maskImageValue("right")};
          mask-image: ${maskImageValue("right")};
        }
      `
          : `
        .swiper {
          margin-left: 16px;
          margin-right: 16px;
        }
      `}
        ${mq["md"]} {
          .swiper-pagination {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default Testimonials;
