import { css } from "@emotion/react";
import { Body } from "../Typography";

interface HomepageHeroJoinProps {}

const HomepageHeroJoin = ({}: HomepageHeroJoinProps) => {
  return (
    <Body
      css={css`
        font-size: 20px;
        font-weight: 500;
        line-height: 140%;
        text-align: left;

        @media (max-width: 1024px) {
          text-align: center;
        }
      `}
    >
      Join{" "}
      <span
        css={css`
          line-height: 28px;
          position: relative;
        `}
      >
        {process.env.organizationCount}+
        <svg
          width="58"
          height="10"
          viewBox="0 0 58 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            position: absolute;
            bottom: -8px;
            left: 0px;
          `}
        >
          <path
            d="M1.4248 8.81628C13.3844 4.24329 30.6204 0.725762 56.4536 1.593"
            stroke="#59FFA0"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </span>{" "}
      sports organizations.
    </Body>
  );
};

export default HomepageHeroJoin;
